import { ButtonPrimary } from 'components/buttons/button-primary/button-primary';
import { TextButton } from 'components/buttons/text-button/text-button';
import OmnijusLogo from 'components/omnijus-logo';
import React from "react";
import { useAuth } from "../context/auth/auth-context";
import styles from "./erro-sessao.module.scss";

export const ErroSessao = (props: { message: string }) => {
  const auth = useAuth();
  return (
    <div className={styles.erroSessao}>
      <p><OmnijusLogo black /></p>
      <p>{props.message}</p>
      <p>
        <ButtonPrimary onClick={() => window.location.reload()}>
          Tentar novamente
        </ButtonPrimary>
      </p>
      <p>
        <TextButton onClick={() => auth?.userManager.signoutRedirect()}>
          Sair e tentar com outro usuário
        </TextButton>
      </p>
    </div>
  );
};
