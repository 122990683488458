const countHashtag = (text: string) => (text.match(/#/g) || []).length;

const filterCharType = (original: string, charType?: "numeric" | "alphabet" | "alphanumeric" | "alphabet-text") => {
    switch (charType) {
        case "numeric":
            return original.replace(/[^0-9]+/gi, "");
        case "alphabet":
            return original.replace(/[^A-Z]+/gi, "");
        case "alphanumeric":
            return original.replace(/[^0-9A-Z]+/gi, "");
        case "alphabet-text":
            return original.replace(/[^A-Z ]+/gi, "");
        default:
            return original;
    }
};

const getBestMask = (original: string, allMasks: Array<Mask>) => {
    let bestMask = allMasks[0];

    for (let mask of allMasks) {
        const filteredCharacters = filterCharType(original, mask.charType);

        if (mask.mask === undefined) {
            continue;
        }

        if (filteredCharacters.length - countHashtag(mask.mask) < filteredCharacters.length - countHashtag(bestMask?.mask || "")) {
            bestMask = mask;
        }
    }

    return bestMask;
}

const applyMask = (original: string, allMasks: Array<Mask>) => {
    let mask = getBestMask(original, allMasks);
    let result = "";
    let resultCharacters = filterCharType(original, mask.charType);
    let resultCounter = 0;
    let maskCounter = 0;
    
    if (mask.mask === undefined) {
        result = resultCharacters;
    } else {
        resultCharacters = resultCharacters.substr(0, countHashtag(mask.mask));
        
        while (resultCounter < resultCharacters.length) {
            if (mask.mask[maskCounter] === "#") {
                result += resultCharacters[resultCounter];
                resultCounter++;
            } else {
                result += mask.mask[maskCounter];
            }

            maskCounter++;
        }
    }

    switch (mask.case) {
        case "lowercase":
            result = result.toLowerCase();
            break;
        case "uppercase":
            result = result.toUpperCase();
            break;
        default:
            break;
    }

    return result;
}

const removeMask = (masked: string, allMasks: Array<Mask>) => {
    let mask = getBestMask(masked, allMasks);
    let characters = filterCharType(masked, mask.charType);
    
    return mask.mask ? characters.substr(0, countHashtag(mask.mask)) : characters;
}

export interface Mask {
    mask?: string;
    case?: "uppercase" | "lowercase" | "normal";
    charType?: "numeric" | "alphabet" | "alphanumeric" | "alphabet-text";
}

export const maskString = (original: string, masks: Array<Mask>) => {
    return original ? applyMask(original.toString(), masks) : original;
};

export const unmaskString = (masked: string, masks: Array<Mask>) => {
    return masked ? removeMask(masked.toString(), masks) : masked;
}