export const Format = {
    toFixedInteger: (original: number, digits: number) => {
        return original?.toLocaleString("pt-BR", { minimumIntegerDigits: digits });
    },
    toBRL: (original: number, options?: { minimumFractionDigits?: number, maximumFractionDigits?: number }) => {
        return original.toLocaleString("pt-BR", { 
            style: "currency", 
            currency: "BRL", 
            minimumFractionDigits: options?.minimumFractionDigits, 
            maximumFractionDigits: options?.maximumFractionDigits 
        });
    },
    toNumberString: (original: number) => {
        return original?.toLocaleString("pt-BR");
    },
    arrayParamsToQueryString: (params: Array<string>) => {
        let result = "";

        if (params.length > 0) {
            result += `?${params.join("&")}`;
        }

        return result;
    },
    strToBoolean: (str: string): boolean | undefined => {
        str = str.toLowerCase().trim();
        if (str === "true") {
            return true;
        }
        if (str === "false") {
            return false;
        }
        return undefined;
    },
    numberOrUndefToStr: (n?: number) => (n !== undefined ? `${n}` : undefined),
    primeiraLetraMaiuscula: (str: string) => str.charAt(0).toUpperCase() + str.slice(1),
    formatCellphoneNumber: (str: string) =>
        str.replace(/^(\d{2})(\d{5})(\d{4}).*/, "$1 $2-$3").replace(/^(\d{2})(\d{4})(\d{4}).*/, "$1 $2-$3"),
    formatNumeroProcesso: (numeroProcesso: string) => {
        const regexp = /(\d{7})(\d{2})(\d{4})(\d{1})(\d{2})(\d{4})/;
        const match = numeroProcesso.match(regexp);
        if (!match) {
            return numeroProcesso;
        }
        return `${match[1]}-${match[2]}.${match[3]}.${match[4]}.${match[5]}.${match[6]}`;
    },
};
