import classNames from "classnames";
import React from "react";
import styles from "./button-secondary.module.scss";

interface ButtonSecondaryProps {
    value?: string;
    onClick?: () => void;
    children?: JSX.Element | string;
    type?: "button" | "submit" | "reset";
    className?: string;
    disabled?: boolean;
}

export const ButtonSecondary = (props: ButtonSecondaryProps) => {
    return (
        <button
            type={props.type || "button"}
            onClick={props.onClick}
            className={classNames(styles["btn-secondary"], props.className)}
            value={props.value}
            disabled={props.disabled}
        >
            {props.children}
        </button>
    );
};
