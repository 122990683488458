import React from "react";
import { PropsWithChildren } from "react";
import { useControleAcesso } from './controle-acesso-context';

export function WrapperAcessoAcao(props: PropsWithChildren<{ tags: string[] }>) {
    const acessos = useControleAcesso();

    if (acessos.possuiAcessoAcao(...props.tags)) {
        return <>{props.children}</>;
    }

    return <></>;
}

export function WrapperAcessoFuncionalidade(props: PropsWithChildren<{ tags: string[] }>) {
    const acessos = useControleAcesso();

    if (acessos.possuiAcessoFuncionalidade(...props.tags)) {
        return <>{props.children}</>;
    }

    return <></>;
}
