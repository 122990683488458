import { Log as OidcLog, User, UserManager, UserManagerSettings } from "oidc-client";
import React, { PropsWithChildren, useEffect, useMemo, useState } from "react";
import { AuthContext } from "./auth-context";

export type AuthProviderProps = PropsWithChildren<{
    userManagerSettings: UserManagerSettings;
    callbackComponent: React.ReactNode;
    callbackPath?: string;
    silentCallbackPath?: string;
}>;

export const AuthProvider = (props: AuthProviderProps) => {
    const userManager = useMemo(() => {
        const um = new UserManager(props.userManagerSettings);
        um.events.addSilentRenewError((e) => {
            console.log("silent renew error", e);
            um.removeUser().then(() => window.location.reload());
        });
        um.events.addUserSignedOut(() => {
            console.log("user signed out");
            um.removeUser().then(() => window.location.reload());
        });
        um.events.addUserSessionChanged(() => {
            console.log("user session changed");
        });
        return um;
    }, [props.userManagerSettings]);

    const callbackPath = props.callbackPath || "/authentication/callback";
    const silentCallbackPath = props.silentCallbackPath || "/authentication/silent_callback";
    const isInCallbackPath = window.location.pathname === callbackPath;
    const isInSilentCallbackPath = window.location.pathname === silentCallbackPath;
    const [user, setUser] = useState<User>();

    useEffect(() => {
        (async () => {
            OidcLog.logger = console;
            // Oidc.Log.level = Oidc.Log.DEBUG;
            if (isInCallbackPath || isInSilentCallbackPath) {
                let user: Oidc.User | null;
                try {
                    user = await userManager.signinCallback();
                } catch {
                    user = await userManager.getUser();
                }
                setUser(user || undefined);
                const url = user?.state || "/";
                window.history.replaceState({}, "", url);
                window.location = url;
            } else {
                const user = await userManager!.getUser();
                if (!user || user.expired) {
                    try {
                        const user = await userManager.signinSilent({ state: window.location.href });
                        setUser(user);
                    } catch (er) {
                        userManager.signinRedirect({ state: window.location.href });
                    }
                } else {
                    setUser(user);
                }
            }
        })();
    }, [isInCallbackPath, isInSilentCallbackPath, userManager]);

    return (
        <AuthContext.Provider value={{ userManager, user }}>
            {(!user || isInCallbackPath) && props.callbackComponent ? props.callbackComponent : props.children}
        </AuthContext.Provider>
    );
};
