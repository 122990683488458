import React from "react";
import { ReactComponent as LogoSvg } from "./omnijus-logo.svg";
import styles from "./omnijus-logo.module.scss";

interface OmnijusLogoProps {
  pulse?: boolean;
  black?: boolean;
}

export const OmnijusLogo = (props: OmnijusLogoProps) => {
  return (
    <LogoSvg
      className={styles.omnijusLogo
        + (props.pulse ? ` ${styles.pulse}` : "")
        + (props.black ? ` ${styles.black}` : "")}
    />
  );
};

export default OmnijusLogo;
