import { User, UserManager } from "oidc-client";
import { createContext, useContext } from "react";

export interface AuthContextType {
  userManager: UserManager;
  user?: User;
}

export const AuthContext = createContext<AuthContextType | null>(null);

export const useAuth = () => useContext(AuthContext);
