import { useAuth } from 'components/context/auth';
import { ErroSessao } from 'components/erro-sessao/erro-sessao';
import { Loading } from 'components/loading/loading';
import { ActionFeedback } from 'lib/action-feedback';
import { ControleAcessoExtras, listarAcessos } from 'lib/controle-acesso/controle-acesso-service';
import { FuncionalidadeAcessoUser } from 'lib/controle-acesso/models/funcionalidade-acesso-user';
import React, { PropsWithChildren, useContext, useEffect, useState } from "react";

export class ControleAcesso {
    constructor(private acessos: FuncionalidadeAcessoUser[]) { }

    private get tagsFuncionalidades() {
        return this.acessos.map((f) => f.tag);
    }

    private get tagsAcoes() {
        return this.acessos
            .map((f) => f.acoes)
            .reduce((a, b) => [...a, ...b], [])
            .map(a => a.tag);
    }

    public possuiAcessoFuncionalidade(...tagsFuncionalidade: string[]) {
        return this.tagsFuncionalidades.some((tag) => tagsFuncionalidade.includes(tag));
    }

    public possuiAcessoAcao(...tagsAcao: string[]) {
        return this.tagsAcoes.some((tag) => tagsAcao.includes(tag));
    }

    public possuiAcessoModulo() {
        return this.acessos.length > 0;
    }
}

export const ControleAcessoContext = React.createContext<ControleAcesso>(new ControleAcesso([]));

export const ControleAcessoProvider = (props: PropsWithChildren<{ clientId: string, baseUrl: string, idCliente? : string, idEscritorio? : string}>) => {
    const auth = useAuth();
    const [promiseControleAcesso, setPromiseControleAcesso] =
        useState<Promise<ControleAcesso | null>>();

    useEffect(() => {
        const promise = listarAcessos(props.clientId, props.baseUrl, {
            idCliente: props.idCliente, 
            idEscritorio: props.idEscritorio,
        })
            .then((acessos) => new ControleAcesso(acessos))
            .catch(async (e) => {
                await ActionFeedback.error({
                    icon: 'error',
                    titleText: "Erro ao acessar os servidores da Omnijus",
                    text: e,
                });
                if (e.statusCode && e.statusCode !== 500) {
                    auth?.userManager.signoutRedirect();
                }

                return null;
            });
        setPromiseControleAcesso(promise);
    }, [auth?.userManager]);

    return (
        <Loading promise={promiseControleAcesso}>
            {(controleAcesso) => {
                if (!controleAcesso) {
                    return <ErroSessao message="Erro ao buscar permissões" />
                }

                if (!controleAcesso.possuiAcessoModulo()) {
                    return <ErroSessao message="Você não possui acesso a esse módulo" />
                }

                return <ControleAcessoContext.Provider value={controleAcesso}>
                    {props.children}
                </ControleAcessoContext.Provider>
            }
            }
        </Loading>
    );
};

export const useControleAcesso = () => useContext(ControleAcessoContext);
